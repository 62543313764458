import { useState, useEffect } from 'react'
import axios from 'axios'

function useLocation() {
  // creating IP state
  const [locationIP, setLocationIP] = useState('')

  // creating function to load ip address from the API
  const getData = async () => {
    await axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        // console.log("🚀 ~ file: use-location.js:12 ~ .then ~ res", res)
        setLocationIP(res.data.country_code)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return locationIP
}

export default useLocation
