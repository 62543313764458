/* eslint-disable no-nested-ternary */
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect } from "react"
import "./two-columns.scss"
import { t } from "i18next"
import { Tagline, Button, GradientBanner } from "../../../../components"
import { navigate } from "gatsby"
import { useTranslation } from 'react-i18next'

const TwoColumnsAboutUS = ({ data, idx }) => {
	const { i18n } = useTranslation();
  const handleButtonClick = url => {
    // Save the current scroll position before navigating
    localStorage.setItem("scrollPosition", window.scrollY)
    navigate(url)
  }

  useEffect(() => {
    // Get the saved scroll position
    const savedScrollPosition = localStorage.getItem("scrollPosition")

    // Restore the scroll position if available
    if (savedScrollPosition) {
      window.scrollTo(0, savedScrollPosition)
      localStorage.removeItem("scrollPosition") // clear the saved position
    }
  }, [])

  const flipBlock = classNames({
    row: true,
    flip_block: _.includes(data.slug, "csr"),
  })

  const containerClass = classNames({
    twoColumnsAbout__container: true,
    center: _.includes(data.slug, "csr"),
    our__vision: _.includes(data.slug, "our-vision"),
    our__story: _.includes(data.slug, "our-story"),
  })

  const aboutUsImage = classNames({
    aboutUs__image: true,
    aboutUs__image__shadow: true,
    right: _.includes(data.slug, "csr"),
    left:
      _.includes(data.slug, "leadership") || _.includes(data.slug, "wireless"),
  })

  const getWidthImage = slug => {
    let width
    let classRow
    switch (true) {
      case _.includes(slug, "csr"):
        width = "calc(100% + 20px)"
        classRow = "col-12 col-md-6 twoColumnsAbout__csr"
        break
      case _.includes(slug, "wireless"):
        width = "390px"
        classRow = "col-12 col-md-6 d-flex justify-content-end"
        break
      case _.includes(slug, "leadership"):
        width = "80%"
        classRow = "col-12 col-md-5 twoColumnsAbout__leadership"
        break
      default:
        width = "100%"
        classRow = "col-12 col-md-6 twoColumnsAbout__general"
        break
    }
    return {
      width,
      classRow,
    }
  }

  return (
    <React.Fragment>
      {_.includes(data.slug, "our-vision") && (
        <div className="twoColumnsAbout__gradient">
          <GradientBanner />
        </div>
      )}
      <div className={containerClass} key={idx}>
        <div className={flipBlock}>
          <div className={getWidthImage(data.slug).classRow}>
            <div
              style={{
                maxWidth: getWidthImage(data.slug).width,
              }}
              className={aboutUsImage}
            >
              <img src={data.image.url} alt={data.image.url} />
            </div>
          </div>
          <div className="col-12 col-md-6 twoColumnsAbout__content">
            {data.subtitle && <Tagline text={data.subtitle} />}
            <div
              className="aboutUs__title rich-text"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              className="aboutUs__copy rich-text"
              dangerouslySetInnerHTML={{ __html: data.copy }}
            />
            {!_.isEmpty(data.link.url) ? (
              data.slug === "wireless" ? (
                <Button
                  onClick={() =>
                    handleButtonClick("https://www.sgwireless.com/")
                  }
                  text={t("about.wireless")}
                />
              ) : (
                <Button
                  onClick={() => handleButtonClick(`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}${data.link.url}`)}
                  text={t("about.more")}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TwoColumnsAboutUS
