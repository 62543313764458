function getCustomRadiusForZoom(zoomLevel) {
  let radius
  switch (zoomLevel) {
    case 22:
    case 21:
    case 20:
      radius = 5
      break
    case 19:
    case 18:
    case 17:
    case 16:
    case 15:
      radius = 50
      break
    case 14:
      radius = 150
      break
    case 13:
      radius = 300
      break
    case 12:
      radius = 500
      break
    case 11:
      radius = 750
      break
    case 10:
      radius = 1500
      break
    case 9:
      radius = 3000
      break
    case 8:
    case 7:
      radius = 6000
      break
    case 6:
    case 5:
      radius = 12000
      break
    case 4:
    case 3:

      radius = 24000
      break
    case 2:
    case 1:
      radius = 90000
      break
    default:
      radius = 3000
      break
  }

  return radius
}

export default getCustomRadiusForZoom
