import _ from "lodash"
import React from "react"
import "./about-us-styles.scss"
import { useTranslation } from "react-i18next"

import { BannerCTA, Seo, Spinner } from "../../components"

import {
  HeadAbout,
  Location,
  Timeline,
  TwoColumnsAboutUs,
  Values,
} from "./components"

const AboutUSView = ({ data, loading, location }) => {
  const { t } = useTranslation()
  const { hash } = location

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="aboutUs__container">
      <Seo title={_.get(data, "title")} />
      <div className="aboutUs__box">
        {_.map(_.get(data, "acf.rows"), (layouts, idx) => {
          let layout
          switch (layouts.acf_fc_layout) {
            // Our mission
            case "hero_two_images_copy":
              layout = <HeadAbout data={layouts} t={t} key={idx} />
              break

            // numbers why
            case "two_column_info_image":
              layout = <TwoColumnsAboutUs data={layouts} t={t} key={idx} />
              break

            // why partner with us
            case "numbered_titles_image":
              layout = <Values data={layouts} t={t} key={idx} />
              break

            case "timeline":
              layout = <Timeline data={layouts} t={t} key={idx} />
              break

            case "locations":
              layout = <Location hash={hash} data={layouts} t={t} key={idx} />
              break

            case "case_studies_list":
              layout = <TwoColumnsAboutUs data={layouts} t={t} key={idx} />
              break

            case "clients":
              layout = <TwoColumnsAboutUs data={layouts} t={t} key={idx} />
              break

            default:
              break
          }
          return layout
        })}
      </div>
      <BannerCTA />
    </div>
  )
}

export default AboutUSView
