import React from 'react'
import './head-about.scss'
import { Trans, useTranslation } from 'react-i18next'
import {
  TitleTextBlock,
} from '../../../../components'

const HeadAbout = ({
  data,
  idx,
  t,
}) => {

    const { i18n } = useTranslation();

    return (
        <div className="headAbout__container" key={idx}>
          <div className="headAbout__col--image">
            <div className="headAbout__col--image__box">
              <img src={data.image.url} alt={data.image.alt} />
            </div>
          </div>
          <div className="headAbout__col--content">
            <TitleTextBlock
              text={t('about.tag')}
              descr={data.copy}>
              <Trans i18nKey='about.title'>
                Improve <span className='title-color'>{{ world: i18n.language !== 'en' ? '世界' : "world's" }}</span> living standard.
              </Trans>
            </TitleTextBlock>
            <div className="headAbout__col--content__image">
              <img src={data.image_second.url} alt={data.image_second.alt} />
            </div>
          </div>
      
        </div>
      )
}

export default HeadAbout
