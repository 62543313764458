import _ from "lodash"
import React, { useState, useRef, useEffect } from "react"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Slider from "react-slick"
import { ButtonCircle } from "../../../../components"
import "./timeline.scss"

const Timeline = ({ data, idx }) => {
  const carRef = useRef(null)
  const breakpoints = useBreakpoint()
  const newData = _.map(data.list, item => ({
    data: item.year,
    status: "status",
  }))
  const [curIdx, setCurIdx] = useState(0)
  const [itemSelected, setItemSelected] = useState({})
  const [sortText, setSortText] = useState(true)
  const visibleItemsCount = breakpoints.lg
    ? 4
    : breakpoints.md
    ? 1
    : breakpoints.sm
    ? 2
    : 1

  const onClick = action => {
    if (action === "back" && curIdx > 0) {
      carRef.current.slickPrev()
    }
    if (action === "forward" && curIdx < newData.length - visibleItemsCount) {
      carRef.current.slickNext()
    }
    // console.log("curIdx: ", curIdx)
    // console.log("visibleItemsCount: ", visibleItemsCount)
  }
  const onClickGoTo = i => {
    setCurIdx(i)
    carRef.current.slickGoTo(i)
  }
  const onHandleShowFulltext = () => {
    setSortText(false)
  }

  useEffect(() => {
    setItemSelected(data.list[curIdx])
  }, [curIdx])

  useEffect(() => {
    setItemSelected(data.list[0])
  }, [])

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    swipeToSlide: false,
    swipe: false,
    afterChange: current => {
      // console.log("afterChange: ", current)
      setCurIdx(current)
    },
    beforeChange: (current, next, props) => {
      // setImageIndex(next)
    },
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          swipeToSlide: false,
          arrows: true,
          centerMode: true,
          centerPadding: "29px 0px 0px",
        },
      },
    ],
  }

  return (
    <div className="timeline__container" key={idx}>
      <div className="scrollTimeline__container">
        <Slider ref={carRef} {...settings}>
          {_.map(newData.concat([{}, {}, {}]), (item, i) => (
            <div key={i}>
              <div className="scrollTimeline__item">
                {_.get(item, "data") && (
                  <React.Fragment>
                    <span
                      className="scrollTimeline__year"
                      onClick={() => onClickGoTo(i)}
                    >
                      {_.get(item, "data")}
                    </span>
                    <span
                      className="scrollTimeline__circle"
                      onClick={() => onClickGoTo(i)}
                    ></span>
                  </React.Fragment>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="timeline__content row">
        <div className="col-12 order-md-2 col-md-6 timeline__image">
          <img
            src={_.get(itemSelected, "image.url", " ")}
            alt={_.get(itemSelected, "image.alt", "no-image")}
          />
        </div>
        <div className="col-12 order-md-1 col-md-6 timeline__card">
          <h2>{_.get(itemSelected, "title")}</h2>
          {breakpoints.xs && sortText ? (
            <HTMLEllipsis
              onClick={onHandleShowFulltext}
              unsafeHTML={_.get(itemSelected, "copy")}
              maxLine="3"
              ellipsis="More +"
              basedOn="letters"
            />
          ) : (
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: _.get(itemSelected, "copy") }}
            />
          )}
        </div>
        <div className="timeline__buttons">
          <ButtonCircle
            disabled={curIdx === 0}
            leftArrow
            onClick={() => onClick("back")}
          />
          <ButtonCircle
            disabled={curIdx >= newData.length - visibleItemsCount}
            onClick={() => onClick("forward")}
          />
        </div>
      </div>
    </div>
  )
}

export default Timeline
